import { devMode } from '../../util/env'
import omit from 'ramda/es/omit'

export const SUBMIT = 'lbs001/lbsrechner/SUBMIT'
export const SUBMIT_SUCCESS = 'lbs001/lbsrechner/SUBMIT_SUCCESS'
export const SUBMIT_SUCCESS_VALUES = 'lbs001/lbsrechner/SUBMIT_SUCCESS_VALUES'
export const SUBMIT_FAILURE = 'lbs001/lbsrechner/SUBMIT_FAILURE'

export const initialState = {
  results: {
    bausparen: {},
    baufinanzierung: {},
    modernisierung: {},
  },
  resultValues: {
    modernisierung: {},
  },
  isSubmitting: {
    bausparen: false,
    baufinanzierung: false,
    modernisierung: false,
  },
  errors: {
    bausparen: false,
    baufinanzierung: false,
    modernisierung: false,
  },
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SUBMIT:
      return {
        ...state,
        isSubmitting: { ...state.isSubmitting, [payload.type]: true },
        errors: { ...state.errors, [payload.type]: false },
      }
    case SUBMIT_SUCCESS:
      return {
        ...state,
        isSubmitting: { ...state.isSubmitting, [payload.type]: false },
        results: { ...state.results, [payload.type]: payload.data },
      }
    case SUBMIT_SUCCESS_VALUES:
      return {
        ...state,
        resultValues: { ...state.resultValues, [payload.type]: payload.data },
      }
    case SUBMIT_FAILURE:
      return {
        ...state,
        isSubmitting: { ...state.isSubmitting, [payload.type]: false },
        results: { ...state.results, [payload.type]: {} },
        errors: { ...state.errors, [payload.type]: payload.error },
      }
    default:
      return state
  }
}

const toOmit = (data, type) => {
  if (type === 'modernisierung') {
    if (data.modernisierungszeitpunkt === 'sofort') {
      return ['abwann']
    }
    if (data.modernisierungszeitpunkt === 'perspektivisch') {
      return ['gesamtlaufzeit']
    }
  }

  return []
}

export const submit = (data, type) => dispatch => {
  dispatch({
    payload: { type },
    type: SUBMIT,
  })

  const url = `https://ws-netfix${
    devMode ? '-test' : ''
  }.wrz.de/NETFIX-WS/lbswest/WEBBAUFI`

  return fetch(`${url}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    body: JSON.stringify(omit(toOmit(data, type))(data)),
  })
    .then(response => {
      if (![200, 400].includes(response.status)) {
        const message = response.statusText
          ? response.status + ': ' + response.statusText
          : response.status

        throw new Error(message)
      }

      return response.json().then(json => {
        if (devMode) console.log('WRZ RESPONSE', json) //eslint-disable-line

        if (json && json.fehlerkz === '0') {
          dispatch({
            payload: { type, data: json },
            type: SUBMIT_SUCCESS,
          })

          return json
        } else if (json && json.fehlerkz === '1') {
          throw new Error(json.fehler)
        }
      })
    })
    .catch(error => {
      if (devMode) console.error(error) //eslint-disable-line
      dispatch({
        payload: {
          type,
          error: error.message,
        },
        type: SUBMIT_FAILURE,
      })
      throw error
    })
}

export const getLabels = (data, type) => dispatch => {
  const url = `https://ws-netfix${
    devMode ? '-test' : ''
  }.wrz.de/NETFIX-WS/lbswest/WEBBAUFI`

  return Promise.all(
    data.map(item => {
      return fetch(`${url}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: JSON.stringify(omit(toOmit(item, type))(item)),
      })
        .then(response => {
          if (![200, 400].includes(response.status)) {
            const message = response.statusText
              ? response.status + ': ' + response.statusText
              : response.status

            throw new Error(message)
          }
          return response.json().then(json => {
            return json
          })
        })
        .catch(error => {
          if (devMode) console.error(error) //eslint-disable-line
          dispatch({
            payload: {
              type,
              error: error.message,
            },
            type: SUBMIT_FAILURE,
          })
          throw error
        })
    })
  ).then(response => {
    dispatch({
      payload: { type, data: response },
      type: SUBMIT_SUCCESS_VALUES,
    })
  })
}
