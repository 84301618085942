export const API_IS_LOADED = 'lbs001/google/API_IS_LOADED'

export const initialState = {
  isLoaded: false,
}

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case API_IS_LOADED:
      return {
        ...state,
        isLoaded: true,
      }
    default:
      return state
  }
}

export const apiIsLoaded = () => dispatch => {
  dispatch({
    type: API_IS_LOADED,
  })
}
