const TOGGLE_MODAL = 'ctabar/TOGGLE_MODAL'

const initialState = {
  isOpened: false,
}

export default (state = initialState, action) => {
  const { type, payload = {} } = action

  switch (type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        isOpened: payload,
      }
    default:
      return state
  }
}

export const toggleCTAModal = isOpened => dispatch => {
  dispatch({
    type: TOGGLE_MODAL,
    payload: isOpened,
  })
}
