export default {
  init() {
    // JavaScript to be fired on all pages
    if ($('.js-modal-toggle').length) {
      $('.js-modal-toggle a').on('click', function () {
        $('.dhsv_cta_bar').find('.toggle-modal').trigger('click')
      })
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
