import { buildQueryString } from '../../util/string'

export const LOAD_CENTER = 'lbs001/customercenter/LOAD_CENTER'
export const LOAD_CENTER_SUCCESS = 'lbs001/customercenter/LOAD_CENTER_SUCCESS'
export const LOAD_CENTERS = 'lbs001/customercenter/LOAD_CENTERS'
export const LOAD_CENTERS_SUCCESS = 'lbs001/customercenter/LOAD_CENTERS_SUCCESS'

export const initialState = {
  centerLoading: [],
  centerLoaded: {},
  centersLoading: false,
  centersLoaded: [],
}

export default (state = initialState, action) => {
  const { type, payload = {} } = action

  switch (type) {
    case LOAD_CENTER:
      return {
        ...state,
        centerLoading: [...state.centerLoading, payload],
      }
    case LOAD_CENTER_SUCCESS:
      return {
        ...state,
        centerLoading: state.centerLoading.filter(item => item !== payload.id),
        centerLoaded: {
          ...state.centerLoaded,
          [payload.id]: payload,
        },
      }
    case LOAD_CENTERS:
      return {
        ...state,
        centersLoading: true,
      }
    case LOAD_CENTERS_SUCCESS:
      return {
        ...state,
        centersLoading: false,
        centersLoaded: payload,
      }
    default:
      return state
  }
}

export const loadCenter = id => dispatch => {
  const query = buildQueryString({
    id,
  })

  dispatch({
    type: LOAD_CENTER,
    payload: id,
  })

  fetch(`/wp-json/dhsv/v1/customercenter?${query}`).then(response => {
    if (![200, 400].includes(response.status)) {
      const message = response.statusText
        ? response.statusText
        : response.status

      throw new Error(message)
    }

    response.json().then(json => {
      dispatch({
        type: LOAD_CENTER_SUCCESS,
        payload: json,
      })
    })
  })
}

export const loadCenters = ({ lat, lng }) => dispatch => {
  const query = buildQueryString({
    lat,
    lng,
  })

  dispatch({
    type: LOAD_CENTERS,
  })

  fetch(`/wp-json/dhsv/v1/customercenters?${query}`).then(response => {
    if (![200, 400].includes(response.status)) {
      const message = response.statusText
        ? response.statusText
        : response.status

      throw new Error(message)
    }

    response.json().then(json => {
      dispatch({
        type: LOAD_CENTERS_SUCCESS,
        payload: json,
      })
    })
  })
}
