import { combineReducers } from 'redux'
import browser from './browser'
import router from './router'
import lbsrechner from './lbsrechner'
import gform from './gform'
import customercenter from './customercenter'
import google from './google'
import ctabar from './ctabar'

const appReducer = combineReducers({
  browser,
  location: router,
  lbsrechner,
  gform,
  customercenter,
  google,
  ctabar,
})

export default appReducer
