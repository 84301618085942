import React from 'react'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'
import LineAwesome from 'react-line-awesome-svg/components/line-awesome'
import AngleDownSolid from 'react-line-awesome-svg/icons/AngleDownSolid'
import AngleUpSolid from 'react-line-awesome-svg/icons/AngleUpSolid'
import PlusSolid from 'react-line-awesome-svg/icons/PlusSolid'
import MinusSolid from 'react-line-awesome-svg/icons/MinusSolid'
import './styles.scss'

class AccordionWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(key) {
    const { active } = this.state

    if (active === key) {
      this.setState({ active: false })
    } else {
      this.setState({ active: key })
    }
  }

  render() {
    const { items, showBorders, usePlusMinusCaret } = this.props
    const { active } = this.state

    const activeCaret = usePlusMinusCaret ? MinusSolid : AngleUpSolid
    const inactiveCaret = usePlusMinusCaret ? PlusSolid : AngleDownSolid

    return (
      <div className={`dhsv_accordion ${showBorders ? 'show-borders' : ''}`}>
        {items.map(
          (item, i) =>
            i < 5 && (
              <div key={i} className={`${active === i ? 'is-open' : ''}`}>
                <div
                  className="dhsv_accordion_head"
                  onClick={() => this.handleClick(i)}
                >
                  <button>
                    <span
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    ></span>
                    <LineAwesome
                      icon={active === i ? activeCaret : inactiveCaret}
                    />
                  </button>
                </div>
                <AnimateHeight
                  className="dhsv_accordion_body"
                  duration={300}
                  height={active === i ? 'auto' : 0}
                >
                  {typeof item.text == 'object' ? (
                    item.text
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: item.text }} />
                  )}
                </AnimateHeight>
              </div>
            )
        )}
      </div>
    )
  }
}

AccordionWrapper.propTypes = {
  items: PropTypes.array.isRequired,
  showBorders: PropTypes.bool,
  usePlusMinusCaret: PropTypes.bool,
}

export default AccordionWrapper
